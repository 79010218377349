<template>
  <b-modal
    id="promotion-products"
    centered
    size="xl"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @show="openModal"
    @hide="perPage = 10"
  >
    <template #modal-header>
      <h4 class="m-modal__title">Select products</h4>
    </template>
    <b-input-group class="col-4 px-0 mb-3 ml-auto">
      <b-input-group-append>
        <div class="m-input__icon left">
          <i class="far fa-search"></i>
        </div>
      </b-input-group-append>
      <b-form-input
        id="search"
        v-model="searchQuery"
        type="text"
        size="sm"
        placeholder="Search products"
        class="border-left-0 pl-0 py-2 mr-2"
        debounce="500"
      ></b-form-input>
    </b-input-group>
    <b-table
      ref="table"
      :items="items"
      :fields="fields"
      :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
      :busy="fetchingProducts"
      empty-filtered-text="No results"
      empty-text="No products"
      show-empty
      selectable
      select-mode="multi"
      no-select-on-click
      borderless
      sticky-header="72vh"
      responsive="true"
      class="m-table__wrapper"
      table-class="m-table"
      thead-class="m-table__header"
      thead-tr-class="m-table__header-tr"
      tbody-class="m-table__body"
      tbody-tr-class="m-table__body-tr"
    >
      <template #head(select)>
        <b-form-checkbox
          size="md"
          :checked="allSelected"
          @change="selectAll"
        ></b-form-checkbox>
      </template>
      <template #cell(select)="{ item, index }">
        <b-form-checkbox
          size="md"
          :checked="selectedProductIds.includes(item.id)"
          @change="selectRow(item, index, $event)"
        ></b-form-checkbox>
      </template>
      <template #cell(tokens)="{ value }">
        <div class="d-flex align-items-center justify-content-center">
          <span class="mr-3">
            <img
              :src="require('@/assets/images/butterlfy-icon.png')"
              class="token-icon"
              v-b-tooltip.hover="'Number of butterflies assigned to product'"
            />
            x{{ value }}
          </span>
        </div>
      </template>
    </b-table>
    <div class="d-flex">
      <b-pagination
        v-model="currentPage"
        per-page="10"
        :total-rows="totalRows"
        pills
        class="ml-auto"
      />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AddProduct",
  data() {
    return {
      fields: [
        {
          key: "select",
          label: "",
          tdClass: "select",
          thClass: "select",
        },
        {
          key: "name",
          tdClass: "name",
          thClass: "name",
        },
        {
          key: "sku",
          tdClass: "sku",
          thClass: "sku",
        },
        {
          key: "stock",
          tdClass: "stock",
          thClass: "stock",
        },
        {
          key: "source",
          tdClass: "source",
          thClass: "source",
        },
        {
          key: "tokens",
          label: "Product token",
          tdClass: "tokens",
          thClass: "tokens",
        },
      ],
      selectedProducts: [],
    };
  },
  computed: {
    products: {
      get() {
        return this.$store.state.promotion.promotion.products;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_PRODUCTS", value);
      },
    },
    searchQuery: {
      get() {
        return this.$store.state.productsTable.searchQuery;
      },
      set(value) {
        this.$store.commit("productsTable/SET_SEARCH_QUERY", value);
        this.$store.dispatch("productsTable/getProducts");
      },
    },
    currentPage: {
      get() {
        return this.$store.state.productsTable.currentPage;
      },
      set(value) {
        this.$store.commit("productsTable/SET_CURRENT_PAGE", value);
        this.$store.dispatch("productsTable/getProducts");
      },
    },
    totalRows() {
      return this.$store.state.productsTable.totalRows;
    },
    perPage: {
      get() {
        return this.$store.state.productsTable.perPage;
      },
      set(value) {
        this.$store.commit("productsTable/SET_PER_PAGE", value);
      },
    },
    fetchingProducts() {
      return this.$store.state.productsTable.requesting;
    },
    items() {
      return this.$store.state.productsTable.products;
    },
    selectedProductIds() {
      return this.products.map(({ id }) => id);
    },
    allSelected() {
      const itemsIds = this.items.map(({ id }) => id);
      const selectedOnPageIds = this.products.filter(({ id }) =>
        itemsIds.includes(id)
      );

      return selectedOnPageIds.length === itemsIds.length;
    },
  },
  methods: {
    selectAll(state) {
      if (state) {
        this.items.forEach((item) => {
          const ids = this.selectedProducts.map(({ id }) => id);
          if (!ids.includes(item.id)) this.selectedProducts.push(item);
        });
      } else {
        this.items.forEach((item) => {
          const ids = this.selectedProducts.map(({ id }) => id);
          if (ids.includes(item.id)) {
            const index = this.selectedProducts.findIndex(
              ({ id }) => id === item.id
            );
            this.selectedProducts.slice(index, 1);
          }
        });
      }
      this.products = this.selectedProducts;
    },
    selectRow(product, index, status) {
      if (status) {
        this.selectedProducts.push(product);
        this.$refs.table.selectRow(index);
      } else {
        const index = this.selectedProducts.findIndex(
          ({ id }) => product.id === id
        );
        this.selectedProducts.splice(index, 1);
        this.$refs.table.unselectRow(index);
      }
      this.products = this.selectedProducts;
    },
    openModal() {
      this.perPage = 5;
      this.$store.dispatch("productsTable/getProducts");
    },
  },
};
</script>

<style scoped></style>
