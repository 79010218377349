<template>
  <div class="d-flex align-items-center">
    <span>Client will get</span>
    <validation-provider
      tag="div"
      name="tokens"
      rules="required|min_value:1"
      class="d-flex flex-column col-2"
      v-slot="{ errors }"
    >
      <b-form-input
        v-model="tokens"
        type="number"
        size="sm"
        no-wheel
        number
        class="text-center"
        :disabled="requesting"
      ></b-form-input>
      <span class="text-danger error">{{ errors[0] }}</span>
    </validation-provider>
    <span
      >of loyalty points for each online purchase he makes.
      <b>It is only once per day!</b></span
    >
  </div>
</template>

<script>
export default {
  name: "PurchasePromotion",
  computed: {
    requesting() {
      return this.$store.state.promotion.requesting;
    },
    tokens: {
      get() {
        return this.$store.state.promotion.promotion.tokenAmount;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_FIELDS", {
          field: "tokenAmount",
          value,
        });
      },
    },
  },
};
</script>

<style scoped></style>
