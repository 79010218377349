<template>
  <b-table
    :items="items"
    :fields="fields"
    :busy="fetchingPromotions"
    :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
    borderless
    sticky-header="72vh"
    responsive="true"
    class="m-table__wrapper"
    table-class="m-table"
    thead-class="m-table__header"
    thead-tr-class="m-table__header-tr"
    tbody-class="m-table__body"
    :tbody-tr-class="rowClass"
  >
    <template #cell(name)="{ item, value }">
      <i
        v-if="!item.active"
        class="fas fa-exclamation-triangle text-yellow-color"
        v-b-tooltip="
          'Promotion is disabled! Edit the promotion to set it back to active.'
        "
      ></i>
      {{ value }}
    </template>
    <template #cell(tokenAmount)="{ value }">
      <span class="mr-3">
        <img
          :src="require('@/assets/images/butterlfy-icon.png')"
          class="token-icon"
          v-b-tooltip.hover="'Number of butterflies'"
        />
        x{{ value }}
      </span>
    </template>
    <template #cell(actions)="{ item }">
      <div class="d-flex justify-content-end">
        <b-button
          v-if="item.link && item.active"
          variant="action"
          v-b-tooltip.hover="'Copy url'"
          class="mr-2"
          @click="copyUrl(item.link, item.tokenAmount)"
          ><i class="fad fa-copy"></i>
        </b-button>
        <b-button
          variant="action"
          v-b-tooltip.hover="'Edit promotion'"
          @click="edit(item)"
        >
          <i class="fad fa-pencil-alt"></i>
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "PromotionsTable",
  data() {
    return {
      promotionTypesLib: [
        {
          key: "link-link",
          value: "Url link",
        },
        {
          key: "link-survey",
          value: "Survey link",
        },
      ],
      fields: [
        {
          key: "name",
          sortable: true,
          tdClass: "name",
          thClass: "name",
        },
        {
          key: "valid",
          sortable: true,
          tdClass: "valid",
          thClass: "valid",
          formatter: (value) => {
            return `${this.$options.filters.date(
              value.start
            )} - ${this.$options.filters.date(value.end)}`;
          },
        },
        {
          key: "store.name",
          label: "store",
          sortable: true,
          tdClass: "store",
          thClass: "store",
        },
        {
          key: "type",
          label: "Promotion type",
          sortable: true,
          tdClass: "type text-capitalize",
          thClass: "type",
          formatter: (value) => {
            const hasLibValue = this.promotionTypesLib.find(
              ({ key }) => key === value
            );
            return hasLibValue ? hasLibValue.value : value;
          },
        },
        {
          key: "tokenAmount",
          label: "Loyalty points reward",
          sortable: true,
          tdClass: "tokens",
          thClass: "tokens",
        },
        {
          key: "used",
          sortable: true,
          tdClass: "used",
          thClass: "used",
        },
        {
          key: "actions",
          label: "",
          sortable: true,
          tdClass: "actions",
          thClass: "actions",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.promotionsTable.promotions;
    },
    fetchingPromotions() {
      return this.$store.state.promotionsTable.requesting;
    },
  },
  async created() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      await this.$store.dispatch("promotionsTable/getPromotions");
    },
    copyUrl(url, tokenAmount) {
      const el = document.createElement("textarea");
      el.value = "?token=" + url + "&amount=" + tokenAmount;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    edit(item) {
      this.$store.commit("promotion/SET_PROMOTION", item);
      this.$bvModal.show("promotion");
    },
    rowClass(item, type) {
      let className = "";
      if (item && type === "row") {
        if (!item.active) className = "inactive";
      }
      return "m-table__body-tr " + className;
    },
  },
};
</script>

<style scoped>
/deep/.actions {
  width: 5%;
}
/deep/.tokens {
  width: 15%;
}
</style>
