<template>
  <div class="d-flex justify-content-end">
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      pills
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "PromotionsPagination",
  computed: {
    currentPage: {
      get() {
        return this.$store.state.promotionsTable.currentPage;
      },
      set(value) {
        this.$store.commit("promotionsTable/SET_CURRENT_PAGE", value);
        this.$store.dispatch("promotionsTable/getPromotions");
      },
    },
    totalRows() {
      return this.$store.state.promotionsTable.totalRows;
    },
    perPage() {
      return this.$store.state.promotionsTable.perPage;
    },
  },
};
</script>

<style scoped></style>
