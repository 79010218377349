<template>
  <div class="col-12 px-0 mt-3">
    <div class="d-flex justify-content-between mb-3">
      <b-button
        variant="primary"
        size="sm"
        :disabled="requesting"
        @click="$bvModal.show('promotion-products')"
        >Select products</b-button
      >
      <b-input-group class="col-4 px-0">
        <b-input-group-append>
          <div class="m-input__icon left">
            <i class="far fa-search"></i>
          </div>
        </b-input-group-append>
        <b-form-input
          id="search"
          v-model="searchQuery"
          type="text"
          size="sm"
          placeholder="Search products"
          class="border-left-0 pl-0 py-2 mr-2"
          debounce="500"
        ></b-form-input>
      </b-input-group>
    </div>

    <b-table
      ref="table"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="5"
      :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
      :filter="searchQuery"
      empty-filtered-text="No results"
      empty-text="No products selected yet"
      show-empty
      selectable
      select-mode="multi"
      no-select-on-click
      borderless
      sticky-header="72vh"
      responsive="true"
      class="m-table__wrapper"
      table-class="m-table"
      thead-class="m-table__header"
      thead-tr-class="m-table__header-tr"
      tbody-class="m-table__body"
      tbody-tr-class="m-table__body-tr"
    >
      <template #cell(remove)="{ item }">
        <b-button
          variant="action"
          @click="removeProducts(item.id)"
          v-b-tooltip="'Remove selected product'"
          ><i class="fas fa-times"></i
        ></b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      per-page="5"
      :total-rows="items.length"
      pills
      class="ml-auto"
    />
    <div class="d-flex align-items-center">
      <span>When client purchase selected product(s) he gets</span>
      <validation-provider
        tag="div"
        name="tokens"
        rules="required|min_value:1"
        class="d-flex flex-column col-2"
        v-slot="{ errors }"
      >
        <b-form-input
          v-model="tokens"
          type="number"
          size="sm"
          no-wheel
          number
          class="text-center"
          :disabled="requesting"
        ></b-form-input>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <span>of loyalty points.</span>
    </div>
    <add-product />
  </div>
</template>

<script>
import AddProduct from "./modal/AddProduct";
export default {
  name: "ProductPromotion",
  components: { AddProduct },
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: "name",
          tdClass: "name",
          thClass: "name",
        },
        {
          key: "sku",
          tdClass: "sku",
          thClass: "sku",
        },
        {
          key: "stock",
          tdClass: "stock",
          thClass: "stock",
        },
        {
          key: "source",
          tdClass: "source",
          thClass: "source",
        },
        {
          key: "tokens",
          tdClass: "tokens",
          thClass: "tokens",
        },
        {
          key: "remove",
          label: "",
          tdClass: "name",
          thClass: "name",
        },
      ],
      searchQuery: "",
    };
  },
  computed: {
    requesting() {
      return this.$store.state.promotion.requesting;
    },
    tokens: {
      get() {
        return this.$store.state.promotion.promotion.tokenAmount;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_FIELDS", {
          field: "tokenAmount",
          value,
        });
      },
    },
    items() {
      return this.$store.state.promotion.promotion.products;
    },
  },
  methods: {
    removeProducts(selectedId) {
      const index = this.items.findIndex(({ id }) => id === selectedId);
      this.$store.commit("promotion/REMOVE_PRODUCT", index);
    },
  },
};
</script>

<style scoped></style>
