<template>
  <div class="p-4">
    <promotions-search />
    <promotions-table />
    <promotions-pagination />
    <promotion />
  </div>
</template>

<script>
import PromotionsSearch from "../components/promotions/PromotionsSearch";
import PromotionsTable from "../components/promotions/PromotionsTable";
import PromotionsPagination from "../components/promotions/PromotionsPagination";
import Promotion from "../components/promotions/modals/Promotion";
export default {
  name: "MPromotions",
  components: {
    Promotion,
    PromotionsPagination,
    PromotionsTable,
    PromotionsSearch,
  },
  async created() {
    await this.$store.dispatch("promotionsTable/getPromotionsMeta");
    this.$store.commit("productsTable/SET_PER_PAGE", 5);
    await this.$store.dispatch("productsTable/getProducts");
  },
};
</script>

<style scoped></style>
