<template>
  <b-modal
    id="promotion"
    centered
    size="xl"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @hidden="resetState"
  >
    <template #modal-header>
      <h4 class="m-modal__title">{{ edit ? "Edit" : "Create" }} promotion</h4>
    </template>
    <div class="d-flex align-items-center info-box info mb-4">
      <i class="fas fa-info-circle icon mr-3"></i>
      <p class="mb-0">
        Promotions serve us to further reward clients. Promotion brings
        additional loyalty points to the client by triggering some kind of
        action.<br />
        <b>Any changes will be effecting immediately.</b>
      </p>
    </div>
    <validation-observer ref="promotionForm" class="d-flex flex-wrap w-100">
      <validation-provider
        tag="div"
        name="name"
        rules="required"
        class="d-flex flex-column mb-3 col-6 pl-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Name</span>
        <b-form-input
          v-model="name"
          type="text"
          size="sm"
          :disabled="requesting"
        ></b-form-input>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="store"
        rules="required"
        class="d-flex flex-column mb-3 col-6 pr-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Store</span>
        <b-form-select v-model="storeId" size="sm">
          <b-form-select-option
            v-for="store in stores"
            :key="store.id"
            :value="store.id"
            >{{ store.name }}</b-form-select-option
          >
        </b-form-select>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="valid"
        rules="date-validation"
        class="d-flex flex-column mb-3 col-6 pl-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Valid (from - to)</span>
        <m-date-select v-model="valid" />
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="promotion type"
        rules="required"
        class="d-flex flex-column mb-3 col-6 pr-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Promotion type</span>
        <b-form-select v-model="type" size="sm">
          <b-form-select-option
            v-for="(type, index) in types"
            :key="index"
            :value="type"
            >{{ type }}</b-form-select-option
          >
        </b-form-select>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="repeating"
        class="d-flex flex-column mb-3 col-12 px-0"
        v-slot="{ errors }"
      >
        <b-form-checkbox v-model="isRepeating"
          >Promotion is repeating</b-form-checkbox
        >
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <div v-if="isRepeating" class="d-flex flex-column col-12 px-0 mb-3">
        <validation-provider
          tag="div"
          name="repetition interval"
          rules="required|min_value:1"
          class="d-flex flex-column mb-3 col-6 pl-0"
          v-slot="{ errors }"
        >
          <span class="m-input__label is-required"
            >Every N week/s (repetition interval)</span
          >
          <b-form-input
            v-model="perWeeks"
            type="number"
            size="sm"
            no-wheel
            number
            :disabled="requesting"
          ></b-form-input>
          <span class="text-danger error">{{ errors[0] }}</span>
        </validation-provider>
        <div class="d-flex align-items-center">
          <span class="mr-2 font-weight-bold">On</span>
          <b-form-checkbox-group v-model="repeatingDays">
            <b-form-checkbox
              v-for="day in week"
              :key="day"
              :value="day"
              class="week-button"
              >{{ day }}</b-form-checkbox
            >
          </b-form-checkbox-group>
        </div>
      </div>
      <component :is="promotionTypeComponent" />
    </validation-observer>
    <template #modal-footer="{ close }">
      <div class="d-flex align-items-center w-100">
        <b-form-checkbox v-if="edit" v-model="active"
          >Promotion is <b>{{ active ? "enabled" : "disabled" }}</b>
        </b-form-checkbox>
        <div class="d-flex align-items-center ml-auto">
          <b-button
            variant="secondary"
            class="mr-2"
            :disabled="requesting"
            @click="close"
            >Close</b-button
          >
          <b-button variant="primary" :disabled="requesting" @click="validate"
            >Save <span v-if="edit">changes</span></b-button
          >
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import MDateSelect from "../../gadgets/MDateSelect";
import ProductPromotion from "./promotion-types/ProductPromotion";
import UrlPromotion from "./promotion-types/UrlPromotion";
import SurveyPromotion from "./promotion-types/SurveyPromotion";
import ProductTokens from "../../products/modals/ProductTokens";
import PurchasePromotion from "./promotion-types/PurchasePromotion";
export default {
  name: "Promotion",
  components: {
    ProductPromotion,
    PurchasePromotion,
    ProductTokens,
    SurveyPromotion,
    UrlPromotion,
    MDateSelect,
  },
  data() {
    return {
      week: ["mon", "tue", "wen", "thu", "fri", "sat", "sun"],
    };
  },
  computed: {
    requesting() {
      return this.$store.state.promotionsTable.requesting;
    },
    stores() {
      return this.$store.state.promotionsTable.stores;
    },
    types() {
      return this.$store.state.promotionsTable.promotionTypes;
    },
    edit() {
      return this.$store.state.promotion.edit;
    },
    promotionTypeComponent() {
      let component;
      switch (this.type) {
        case "order":
          component = "purchase-promotion";
          break;
        case "survey":
          component = "survey-promotion";
          break;
        case "url":
          component = "url-promotion";
          break;
        case "products":
          component = "product-promotion";
          break;
      }
      return component;
    },
    active: {
      get() {
        return this.$store.state.promotion.promotion.active;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_FIELDS", {
          field: "active",
          value,
        });
      },
    },
    name: {
      get() {
        return this.$store.state.promotion.promotion.name;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_FIELDS", {
          field: "name",
          value,
        });
      },
    },
    storeId: {
      get() {
        return this.$store.state.promotion.promotion.storeId;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_FIELDS", {
          field: "storeId",
          value,
        });
      },
    },
    valid: {
      get() {
        return this.$store.state.promotion.promotion.valid;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_FIELDS", {
          field: "valid",
          value,
        });
      },
    },
    type: {
      get() {
        return this.$store.state.promotion.promotion.type;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_FIELDS", {
          field: "type",
          value,
        });
        this.$store.commit("promotion/UPDATE_PROMOTION_PRODUCTS", []);
      },
    },
    isRepeating: {
      get() {
        return this.$store.state.promotion.promotion.repetition.isRepeating;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_REPETITION", {
          field: "isRepeating",
          value,
        });
        if (!value) {
          this.perWeeks = 0;
          this.repeatingDays = [];
        }
      },
    },
    perWeeks: {
      get() {
        return this.$store.state.promotion.promotion.repetition.perWeeks;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_REPETITION", {
          field: "perWeeks",
          value,
        });
      },
    },
    repeatingDays: {
      get() {
        return this.$store.state.promotion.promotion.repetition.repeatingDays;
      },
      set(value) {
        this.$store.commit("promotion/UPDATE_PROMOTION_REPETITION", {
          field: "repeatingDays",
          value,
        });
      },
    },
  },
  methods: {
    validate() {
      this.$refs.promotionForm.validate().then(async (success) => {
        if (success) {
          if (this.edit) this.save();
          else await this.create();
        }
      });
    },
    async create() {
      try {
        this.$store.commit("promotion/SET_REQUESTING", true);
        const res = await this.$store.dispatch("promotion/createPromotion");
        this.$store.commit("promotionsTable/ADD_PROMOTION", res.data.data);
        this.$bvModal.hide("promotion");
        this.$bvToast.toast("Promotion created", {
          title: "Promotion",
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("An error accrued while creating the promotion", {
          title: "Promotion",
          variant: "danger",
        });
      } finally {
        this.$store.commit("promotion/SET_REQUESTING", false);
      }
    },
    async save() {
      try {
        this.$store.commit("promotion/SET_REQUESTING", true);
        const res = await this.$store.dispatch("promotion/savePromotion");
        this.$store.commit(
          "promotionsTable/UPDATE_PROMOTION_DATA",
          res.data.data
        );
        this.$bvModal.hide("promotion");
        this.$bvToast.toast("Promotion updated", {
          title: "Promotion",
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("An error accrued while updating the promotion", {
          title: "Promotion",
          variant: "danger",
        });
      } finally {
        this.$store.commit("promotion/SET_REQUESTING", false);
      }
    },
    resetState() {
      this.$store.commit("promotion/RESET_STATE");
    },
  },
};
</script>
